<template>
    <b-modal ref="modal"
             @show="resetModal"
             @hidden="resetModal" id="committee-member-lookup-modal" size="xl" v-bind:hide-footer="true" centered title="Search Members">
        <div class="card-body">
            <form ref="form" method="post" id="MemerSearchForm">
                <div class="row">
                    <div class="col-md-3">
                        <label><strong>Search by Member#</strong></label>
                        <div class="form-group">
                            <div class="search-left">
                                <label class="form-label"> Member #</label>
                                <input type="text" class="form-control" maxlength="20" name="memberLookupForm.member_id" v-model="memberLookupForm.member_id" id="searchbyname"  >
                                <span class="text-sm text-danger">{{ errors.member_id }}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 text-center">
                        <strong>OR</strong>
                    </div>
                    <div class="col-md-12">
                        <label><strong>Search by Name</strong></label>
                    </div>
                    <div class="col-md-3">

                        <div class="form-group">
                            <label for="firstname" class="form-label">First Name</label>
                            <input type="text" class="form-control" maxlength="45" placeholder="Dickerson" name="memberLookupForm.first_name" v-model="memberLookupForm.first_name" value="">
                            <span class="text-sm text-danger">{{ errors.first_name }}</span>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="lastname" class="form-label">Last Name</label>
                            <input type="text" class="form-control" maxlength="45" placeholder="Macdonald" name="memberLookupForm.last_name" v-model="memberLookupForm.last_name">
                            <span class="text-sm text-danger">{{ errors.last_name }}</span>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="city" class="form-label">City</label>
                            <input type="text" class="form-control" maxlength="45" placeholder="New Alessiaview" name="memberLookupForm.city" v-model="memberLookupForm.city">
                            <span class="text-sm text-danger">{{ errors.city }}</span>
                        </div>
                    </div>
                    <div class="col-md-3">
                        <div class="form-group">
                            <label for="state" class="form-label">State</label>
                            <select class="form-control" id="state" name="memberLookupForm.state" v-model="memberLookupForm.state">
                                <option value="">Please select</option>
                                <option  v-for="listOfState in listOfStates" :key="listOfState.state" :value="listOfState.state_code">{{ listOfState.state }}</option>
                            </select>
                            <span class="text-sm text-danger">{{ errors.state }}</span>
                        </div>
                    </div>
                    <div class="col-md-12 modal-searchbtn text-center">
                        <div class="form-group">
                            <button type="button" @click="memberSearch();" class="btn btn-primary btn-blue">{{ button.SearchButton }}</button>
                        </div>
                        <div class="text-sm text-danger">{{ errors.not_found }}</div>
                        <div class="text-sm text-success">{{ errors.success }}</div>
                    </div>
                </div>
            </form>
        </div>
        <div>
            <div v-if="memberSerchResultStatus == true" class="card-body primary-contact">
                <table class="table">
                    <thead>
                    <tr>
                        <th scope="col">Member#</th>
                        <th scope="col">Member Details</th>
                        <th scope="col">Position<span class="text-danger"> * </span></th>
                        <th scope="col">Sport Discipline<span class="text-danger"> * </span></th>
                        <th scope="col" class="term_dates">Term From Date</th>
                        <th scope="col" class="term_dates">Term To Date</th>
                        <th scope="col" class="term_dates" v-if="getSiteProps('group.board_span_completed.enabled') && getSiteProps('group.board_span_completed.committeeId').includes(parseInt(selectedCommittee.id))">BoardSpan Completed</th>
                        <th scope="col">Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr v-for="(member, r) in memberSearchResults" :key="r">
                        <th scope="row">{{ member.confirmation_code }}</th>
                        <td>{{ member.personal_details.full_name }}<br />
                          {{ member.contact_details.email }}<br />
                          <i class="fas fa-map-marker-alt"></i> {{ member.contact_details.city }}, {{ member.contact_details.state_code }}</td>
                        <td>
                          <div>
                            <b-form-select
                                id="inline-form-custom-select-pref"
                                class="mb-2 mr-sm-2 mb-sm-0"
                                v-model="position_id_arr[r]"
                                v-on:change="validatePositionId(member,r)"
                                :options="positions"
                                :value="positions.id"
                            ></b-form-select>

                          </div>
                          <div class="text-sm text-danger" :key="position_err_key">{{ position_err[r] }}</div>
                        </td>
                      <td>
                        <div>
                          <b-form-select
                              id="inline-form-custom-select-pref"
                              class="mb-2 mr-sm-2 mb-sm-0"
                              v-model="sport_discipline_id_arr[r]"
                              v-on:change="validateSportDisciplineId(member,r)"
                          >
                            <option value="">Select</option>
                            <option v-for="(discipline, index) in disciplines" :value="index"
                                    :key="'types'+index">{{ discipline }}
                            </option>
                          </b-form-select>
                        </div>
                        <div class="text-sm text-danger" :key="sd_err_key">{{ sd_err[r] }}</div>
                      </td>
                      <td>
                        <div>
                          <div class="input-group birthdate">
                            <div class="birthdaypicker">
                              <date-pick
                                  id="term_from_date"
                                  v-model="term_from_date[r]"
                                  :format="'YYYY-MM-DD'"
                                  :displayFormat="'MM/DD/YYYY'"
                                  v-mask="'##/##/####'"
                                  :parseDate="parseDatePick"
                              ></date-pick>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td>
                        <div>
                          <div class="input-group birthdate">
                            <div class="birthdaypicker">
                              <date-pick
                                  id="term_to_date"
                                  v-model="term_to_date[r]"
                                  :format="'YYYY-MM-DD'"
                                  :displayFormat="'MM/DD/YYYY'"
                                  v-mask="'##/##/####'"
                                  :parseDate="parseDatePick"
                              ></date-pick>
                            </div>
                          </div>
                        </div>
                      </td>
                      <td v-if="getSiteProps('group.board_span_completed.enabled') && getSiteProps('group.board_span_completed.committeeId').includes(parseInt(selectedCommittee.id))">
                        <div>
                          <div class="input-group birthdate">
                            <div class="birthdaypicker">
                              <date-pick
                                  id="board_span_completed"
                                  v-model="board_span_completed[r]"
                                  :format="'YYYY-MM-DD'"
                                  :displayFormat="'MM/DD/YYYY'"
                                  v-mask="'##/##/####'"
                                  :parseDate="parseDatePick"
                              ></date-pick>
                            </div>
                          </div>
                        </div>
                      </td>
                        <td><button class="btn btn-primary btn-success" @click="addMemberToCommittee(member,r)">{{ button.addToCommitteeBtn }}</button></td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>

    </b-modal>
</template>

<script>
import axios from 'axios';

const validateName = name => {
    if (!name.length || name == "") {
        return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
};
const validateSelect = select => {
    if (select == "") {
        return { valid: false, error: "This field is required" };
    }
    return { valid: true, error: null };
};
export default {
    name: "CommitteeMemberLookup",
    props: ["selectedCommittee"],
    data(){
        return {
            memberLookupForm: {
                member_id: '',
                first_name: '',
                last_name: '',
                city: '',
                state: ''
            },
            button: {
                SearchButton: 'Search',
                addToCommitteeBtn: 'Add'
            },
            memberSerchResults: [],
            memberSerchResultStatus: false,
            primaryContact: {
                membership_id: '',
                first_name: '',
                last_name: '',
                email: '',
                phone_1: '',
                address1:'',
                address2:'',
                city: '',
                state: '',
                state_code: '',
                zip: '',
                contact_type: '',
            },
            valid: false,
            success: false,
            errors: {},
            message: null,
            position_id_arr: [],
            sport_discipline_id_arr: [],
            position_err: [],
            sd_err: [],
            term_from_date: [],
            term_to_date: [],
            board_span_completed: [],
            position_btn: [],
            positions: [],
            disciplines:[],
            position_err_key :1,
            sd_err_key:1
        }
    },
    methods:{
        resetAlert() {
            this.displayAlert = false;
        },
        memberSearch: function () {
            if(!this.validate()) {
                return;
            }
            this.button.SearchButton = 'Searching...';
            this.errors= {};
            axios.get(this.basePath+'api/member_lookup?member_id='+this.memberLookupForm.member_id+'&first_name='+this.memberLookupForm.first_name+'&last_name='+this.memberLookupForm.last_name+'&city='+this.memberLookupForm.city+'&state='+this.memberLookupForm.state)
                .then((res) => {
                    if(res.data.status == 'success') {
                        this.position_err = [];
                        this.position_id_arr = [];
                        this.term_from_date = [];
                        this.term_to_date = [];
                        this.sport_discipline_id_arr = [];
                        this.button.SearchButton = 'Search';
                        this.memberSerchResultStatus = true;
                        this.memberSearchResults = res.data.data;
                      for(let i = 0; i <= this.memberSearchResults.length; i++)
                      {
                        this.position_id_arr[i] = "";
                        this.sport_discipline_id_arr[i] = "";
                        this.term_from_date[i] = "";
                        this.term_to_date[i] = "";
                        this.board_span_completed[i] = "";
                      }
                    } else {
                        this.errors.not_found = 'Officer needs to join as an active (Individual Active, Under 25 Active, Family Active, Ambassador Lifetime or Life Active) member!';
                        this.button.SearchButton = 'Search';
                        this.memberSerchResultStatus = false;
                        return false;
                    }
                })
                .catch((error) => {
                    this.errors.not_found = 'Officer needs to join as an active (Individual Active, Under 25 Active, Family Active, Ambassador Lifetime or Life Active) member!';
                    this.memberSerchResultStatus = false;
                    this.button.SearchButton = 'Search';
                    return false;
                });
        },
        resetModal() {
            this.memberSerchResultStatus = false;
            this.errors.success = "";
            this.position_err = [];
            this.position_id_arr = [];
            this.sport_discipline_id_arr = [];
            this.memberSearchResults = [];
            this.memberLookupForm = {
                member_id: '',
                first_name: '',
                last_name: '',
                city: '',
                state: ''
            };
        },
        validatePositionId(member,r)
        {
            if(this.position_id_arr[r] == "") {
            this.position_err[r] = "Please select member position!";
            this.position_err_key++;
            return false;
          }
          else{
            this.position_err[r] = "";
            this.position_err_key++;
          }
          return true;
        },
      validateSportDisciplineId(member,r)
      {
        if(this.sport_discipline_id_arr[r] == "") {
          this.sd_err[r] = "Please select sport discipline!";
          this.sd_err_key++;
          return false;
        }
        else{
          this.sd_err[r] = "";
          this.sd_err_key++;
        }
        return true;
      },
        addMemberToCommittee(member,r) {
          let positionvalidate = this.validatePositionId(member,r);
          let sdvalidate = this.validateSportDisciplineId(member,r);
          if(!positionvalidate||!sdvalidate) return false;
            this.button.addToCommitteeBtn = 'Add';
            this.errors = {};
            let data = {
              committee_id: this.selectedCommittee.id,
              membership_id: member.confirmation_code,
              position_id: this.position_id_arr[r],
              sport_discipline: this.sport_discipline_id_arr[r],
              term_from_date: this.term_from_date[r]||null,
              term_to_date: this.term_to_date[r]||null,
            };
            if(this.getSiteProps('group.board_span_completed.enabled') && this.getSiteProps('group.board_span_completed.committeeId').includes(parseInt(this.selectedCommittee.id))) {
              data.board_span_completed = this.board_span_completed[r]||null;
            }
            this.position_err[r] = "";
            this.sd_err[r] = "";
            axios.post(this.basePath + 'api/committee_members', data, {headers: this.adminHeaders})
                .then((res) => {
                  if (res.data.status == 'success') {
                    this.errors.success = 'Member Added to Groups!';
                    this.button.addToCommitteeBtn = 'Add';
                    this.memberSerchResultStatus = false;

                    this.memberSearchResults = res.data.data;
                    this.valid = true;
                    this.$emit('committee-member-lookup-data', 'success');
                    /*this.$nextTick(() => {
                    this.$bvModal.hide('committee-member-lookup-modal')
                  })*/
                    //this.errors = {};
                  }
                  else if(res.data.status == 'exist')
                  {
                    this.errors.success = 'Member Already Assigned!';
                    this.button.addToCommitteeBtn = 'Add';
                    this.memberSerchResultStatus = false;

                    this.memberSearchResults = res.data.data;
                    this.valid = true;
                    this.$emit('committee-member-lookup-data', 'success');
                  }
                  else {
                    this.errors.not_found = 'Unable to add member to Groups!';
                    this.button.addToCommitteeBtn = 'Add';
                    return false;
                  }
                })
                .catch((error) => {
                    this.memberSerchResultStatus = false;
                  this.errors.not_found = 'Officer needs to join as an active (Individual Active, Under 25 Active, Family Active, Ambassador Lifetime or Life Active) member!';

                  this.button.SearchButton = 'Search';
                  return false;
                });


        },
        validate() {
            this.errors = {}
            this.valid = false;
            const member_id = this.memberLookupForm.member_id;
            const first_name = this.memberLookupForm.first_name;
            const last_name = this.memberLookupForm.last_name;
            const city = this.memberLookupForm.city;
            const state = this.memberLookupForm.state;
            if(member_id == '' && (first_name == '' && last_name == '' && city == '' && state == '')) {
                this.errors.member_id = 'Please Enter Either Member Id or Below Member Details';

                return this.valid;
            }
            if(((first_name == '' && last_name == '')) && (city != '' || state != ''))
            {
                this.errors.first_name = "Enter FirstName";
                this.errors.last_name = "Enter LastName";
                return this.valid;
            }
            if(member_id == '' && (first_name == '' && last_name == '' && city == '' && state == '')) {
                const validFirstName = validateName(this.memberLookupForm.first_name);
                this.errors.first_name = validFirstName.error;

                const validLastName = validateName(this.memberLookupForm.last_name);
                this.errors.last_name = validLastName.error;

                const validCity = validateSelect(this.memberLookupForm.city);
                this.errors.city = validCity.error;

                const validStateCode = validateSelect(this.memberLookupForm.state);
                this.errors.state = validStateCode.error;

                return this.valid;
            }
            this.valid = true;
            this.errors= {}

            return this.valid;
        },
      loadPositions()
      {
        axios
            .get(process.env.VUE_APP_URL + "api/committee_positions")
            .then((response) => {
              //this.positions = response.data.committee_positions;
              let positions = Object.keys(response.data.committee_positions);
              if(positions.length && this.positions.length == 0){
                this.positions.push({value:"", text: 'Select'});
                positions.forEach((code,value) => {
                  this.positions.push({ value: response.data.committee_positions[code].id, text: response.data.committee_positions[code].position });
                });
                this.positions.sort((a, b) =>  a.text.localeCompare(b.text));
              }
            })
            .catch((error) => (this.errors = error.response.data));
      },
      getDiscipline()
      {
        axios.get(this.basePath+"api/getDisciplines/Committee")
            .then(response => {
              this.disciplines = response.data.data;
            })
            .catch(err => {
              // Manage the state of the application if the request
              // has failed
            })
      }
    },
  mounted() {
      this.errors = {};
    this.loadPositions();
    this.getDiscipline();
  }
}
</script>
<style>
#committee-member-lookup-modal .modal-xl {
  max-width: 1360px;
}
#committee-member-lookup-modal .modal-body {
  padding: 30px;
}
#committee-member-lookup-modal .card-body {
  padding: 0.25rem;
}
.term_dates{
  min-width: 100px;
}
</style>
