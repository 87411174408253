<template>
  <div class="">

    <!-- Content Header (Page header) -->
    <div class="">
      <custom-alert v-if="displayAlert==true" :message="alertMessage"
                    v-on:showedAlert="resetAlert"></custom-alert>
      <!-- Main content -->
      <section class="content">
        <div class="container-fluid">
          <!-- Main row -->
          <div class="row">
            <section class="col-lg-12 connectedSortable table-area mt-3 pr-0 pl-0 mb-3">
              <div class="card">
                <div class="card-body table-responsive">
                  <div class="row bor-bot">
                    <div class="col-md-8 pl-0">
                      <h1 class="m-0 text-dark pt-2 pb-3">Groups Members</h1>
                    </div>
                    <div class="col-md-4">
                      <div class="text-right pb-3">
                        <ol class="breadcrumb float-sm-right">
                          <li class="breadcrumb-item"><a href="/admin/dashboard">Home</a></li>
                          <li class="breadcrumb-item">Groups</li>
                          <li class="breadcrumb-item">Members</li>
                        </ol>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive mt-3">
                    <div class="data-tablebutn" style="top: 90px;">
                        <button  v-b-modal.committee-member-lookup-modal @click="selectCommittee" class="btn-primary btn-darkblue btn mr-1" v-show="filtersApplied && searchForm.groups && searchForm.groups.length > 0" style="width:140px;">
                          <i class="fas fa-plus"></i> Add Member
                        </button>
                      <b-button class="btn-primary btn-darkblue btn mr-1" :class="{ 'filter-applied': filtersApplied }" v-b-modal.modal-member-report>
                        <i class="fas fa-sliders-h"></i> Filters
                      </b-button>
                      <b-button class="btn-primary btn-darkblue btn" @click="refreshList" v-if="filtersApplied">
                        <i class="fas fa-redo"></i> Reset
                      </b-button>
                        <b-button type="button" class="btn ml-1 btn-primary btn-darkblue btn" @click="exportCsv" :disabled="exportProgress">
                            <i class="fas" :class="[ exportProgress ? 'fa-circle-notch fa-spin' : 'fa-file-download' ]"></i> Export 
                        </b-button>
                        <b-button variant="outline-primary refresh" :disabled="safesportRefreshing" style="margin-left:4px" @click="refreshSafeSport()" class="mr-1">
                            <i class="fas" :class="safesportRefreshing ? 'fa-circle-notch fa-spin' : 'fas fa-sync-alt'"></i> Safe Sport Refresh
                        </b-button>
                    </div>
                    <table class="table table-sm table-bordered table-hover" id="cm_list_table"></table>
                  </div>
                  <b-modal :id="infoModal.id" :title="infoModal.title" size="lg" ok-only @hide="resetInfoModal">
                    <dl class="row">
                      <dt class="col-sm-6">Member</dt>
                      <dd class="col-sm-6">{{ infoModal.content.confirmation_code }}</dd>
                      <dt class="col-sm-6">Committee</dt>
                      <dd class="col-sm-6">{{ infoModal.content.committee }}</dd>

                      <dt class="col-sm-6">Group</dt>
                      <dd class="col-sm-6">{{ infoModal.content.group_text }}</dd>
                      <dt class="col-sm-6">Minimum Age</dt>
                      <dd class="col-sm-6">{{ infoModal.content.group_text }}</dd>
                      <dt class="col-sm-6">Sport Discipline</dt>
                      <dd class="col-sm-6">
                        {{ infoModal.content.sport_discipline }}
                      </dd>
                      <dt class="col-sm-6">Current Safe Sport Training Required</dt>
                      <dd class="col-sm-6">{{ infoModal.content.safe_sport }}</dd>

                      <dt class="col-sm-6">Current Background Screening Required</dt>
                      <dd class="col-sm-6">
                        {{ infoModal.content.screening }}
                      </dd>

                      <dt class="col-sm-6">Background Screening Payment</dt>
                      <dd class="col-sm-6">
                        {{ infoModal.content.payment }}
                      </dd>

                      <dt class="col-sm-6">USA-WSWS
                        Membership
                        Required</dt>
                      <dd class="col-sm-6">
                        {{ infoModal.content.membership_text }}
                      </dd>

                      <dt class="col-sm-6">Signed Code of Conduct/Conflict of Interest Disclosure Required</dt>
                      <dd class="col-sm-6">
                        {{ infoModal.content.signed_code_of_conduct }}
                      </dd>
                      <dt class="col-sm-6">Boardspan Training Required</dt>
                      <dd class="col-sm-6">
                        {{ infoModal.content.boardspan }}
                      </dd>
                      <dt class="col-sm-6">Signed Team Documents Required</dt>
                      <dd class="col-sm-6">
                        {{ infoModal.content.signed_team_documents }}
                      </dd>
                      <dt class="col-sm-6">List on Web Site</dt>
                      <dd class="col-sm-6">
                        {{ infoModal.content.list_on_website }}
                      </dd>
                    </dl>
                  </b-modal>
                  <!-- Edit modal -->
                  <b-modal id="edit-modal" :title="editModal.title" size="lg" ref="modal" @ok="handleOk"
                    @hide="resetEditModal">
                    <form ref="form" @submit.stop.prevent="handleSubmit">
                      <div class="row">
                        <div class="col-md-6">
                          <div>
                            <label class="pt-4 form-label" for="feedback-name">Name</label>
                            <b-form-input @keydown="errors.clear('committee')" v-model="editModal.content.committee"
                              :class="errors.has('committee') ? 'is-invalid' : ''" id="feedback-committee">
                            </b-form-input>
                            <b-form-invalid-feedback :state="!errors.has('committee')">
                              {{ errors.get("committee") }}</b-form-invalid-feedback>
                          </div>
                          <div class="pt-4">
                            <label class="pt-4 form-label" for="feedback-name">Sport Discipline</label>
                            <b-form-input @keydown="errors.clear('sport_discipline')"
                              v-model="editModal.content.sport_discipline"
                              :class="errors.has('sport_discipline') ? 'is-invalid' : ''"
                              id="feedback-sport_discipline"></b-form-input>
                            <b-form-invalid-feedback :state="!errors.has('sport_discipline')">
                              {{ errors.get("sport_discipline") }}</b-form-invalid-feedback>
                          </div>
                          <div>
                            <label class="pt-4 form-label" for="feedback-name">Current
                              Safe
                              Sport
                              Training
                              Required</label>
                            <b-form-input @keydown="errors.clear('safe_sport')" v-model="editModal.content.safe_sport"
                              :class="errors.has('safe_sport') ? 'is-invalid' : ''" id="feedback-safe_sport">
                            </b-form-input>
                            <b-form-invalid-feedback :state="!errors.has('safe_sport')">
                              {{ errors.get("safe_sport") }}</b-form-invalid-feedback>
                          </div>
                          <div>
                            <label class="pt-4 form-label" for="feedback-name">Background
                              Screening
                              Payment</label>
                            <b-form-input @keydown="errors.clear('payment')" v-model="editModal.content.payment"
                              :class="errors.has('payment') ? 'is-invalid' : ''" id="feedback-payment"></b-form-input>
                            <b-form-invalid-feedback :state="!errors.has('payment')">
                              {{ errors.get("payment") }}</b-form-invalid-feedback>
                          </div>
                          <div>
                            <label class="pt-4 form-label" for="feedback-name">Boardspan
                              Training
                              Required</label>
                            <b-form-input @keydown="errors.clear('boardspan')" v-model="editModal.content.boardspan"
                              :class="errors.has('boardspan') ? 'is-invalid' : ''" id="feedback-boardspan">
                            </b-form-input>
                            <b-form-invalid-feedback :state="!errors.has('boardspan')">
                              {{ errors.get("boardspan") }}</b-form-invalid-feedback>
                          </div>
                          <div>
                            <label class="pt-4 form-label" for="feedback-name">Signed
                              Team
                              Documents
                              Required</label>
                            <b-form-input @keydown="errors.clear('signed_team_documents')"
                              v-model="editModal.content.signed_team_documents"
                              :class="errors.has('signed_team_documents') ? 'is-invalid' : ''"
                              id="feedback-signed_team_documents"></b-form-input>
                            <b-form-invalid-feedback :state="!errors.has('signed_team_documents')">
                              {{ errors.get("signed_team_documents") }}</b-form-invalid-feedback>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div>
                            <label class="pt-4 form-label" for="feedback-code">Group</label>
                            <b-form-select id="inline-form-custom-select-pref" class="mb-2 mr-sm-2 mb-sm-0"
                              v-model="editModal.content.committee_group_id" :options="groups" :value="groups.id"
                              size="sm"></b-form-select>
                          </div>
                          <div>
                            <label class="pt-4 form-label" for="feedback-name">Minimum Age</label>
                            <b-form-input @keydown="errors.clear('minimum_age')" v-model="editModal.content.minimum_age"
                              :class="errors.has('minimum_age') ? 'is-invalid' : ''" id="feedback-minimum_age">
                            </b-form-input>
                            <b-form-invalid-feedback :state="!errors.has('minimum_age')">
                              {{ errors.get("minimum_age") }}</b-form-invalid-feedback>
                          </div>
                          <div>
                            <label class="pt-4 form-label" for="feedback-name">Current
                              Background
                              Screening
                              Required</label>
                            <b-form-input @keydown="errors.clear('screening')" v-model="editModal.content.screening"
                              :class="errors.has('screening') ? 'is-invalid' : ''" id="feedback-screening">
                            </b-form-input>
                            <b-form-invalid-feedback :state="!errors.has('screening')">
                              {{ errors.get("screening") }}</b-form-invalid-feedback>
                          </div>
                          <div>
                            <label class="pt-4 form-label" for="feedback-discount_type">USA-WSWS
                              Membership
                              Required</label>
                            <b-form-checkbox v-model="editModal.content.membership" name="membership[]" value="1">Active
                            </b-form-checkbox>
                            <b-form-checkbox v-model="editModal.content.membership" name="membership[]" value="2">
                              Supporting</b-form-checkbox>
                            <span class="text-sm text-danger">{{
                                errors.get("discount_type")
                            }}</span>
                          </div>
                          <div>
                            <label class="pt-4 form-label" for="feedback-name">Signed
                              Code of
                              Conduct/Conflict of Interest Disclosure
                              Required</label>
                            <b-form-input @keydown="errors.clear('signed_code_of_conduct')"
                              v-model="editModal.content.signed_code_of_conduct"
                              :class="errors.has('signed_code_of_conduct') ? 'is-invalid' : ''"
                              id="feedback-signed_code_of_conduct"></b-form-input>
                            <b-form-invalid-feedback :state="!errors.has('signed_code_of_conduct')">
                              {{ errors.get("signed_code_of_conduct") }}</b-form-invalid-feedback>
                          </div>
                          <div>
                            <label class="pt-4 form-label" for="feedback-name">List on
                              Web Site</label>
                            <b-form-input @keydown="errors.clear('list_on_website')"
                              v-model="editModal.content.list_on_website"
                              :class="errors.has('list_on_website') ? 'is-invalid' : ''" id="feedback-list_on_website">
                            </b-form-input>
                            <b-form-invalid-feedback :state="!errors.has('list_on_website')">
                              {{ errors.get("payment") }}</b-form-invalid-feedback>
                          </div>
                        </div>
                      </div>
                    </form>
                  </b-modal>
                  <!-- filter modal -->
                  <b-modal id="modal-member-report" v-bind:hide-footer="true" ref="modal-member-report" size="lg"  title="Advanced Search">
                    <form ref="form" method="post" @submit.prevent="advancedSearch()">
                      <div class="row">
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">Member Id</label>
                            <input type="text" id="confirmation_code" class="form-control" v-model="searchForm.confirmation_code">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">First Name</label>
                            <input type="text" id="first_name" class="form-control" v-model="searchForm.first_name">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">Last Name</label>
                            <input type="text" id="last_name" class="form-control" v-model="searchForm.last_name">
                          </div>
                        </div>
                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">Group</label>
                            <select v-model="searchForm.groups" name="groups" id="groups" class="form-control">
                              <option v-for="(committee,id) in committeeList" :value="committee.id" :key="id">{{ committee.text}}</option>
                            </select>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">Sport Discipline</label>
                            <Select2 v-model="searchForm.discipline" name="discipline" id="discipline" :options="disciplines" :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">Position</label>
                            <Select2 v-model="searchForm.position" name="position" id="position" :options="multiplePositions"  :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}" />
                            </div>
                          </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">Term Dates</label>
                            <b-form-select
                                id="term_dates"
                                class="form-control term_date"
                                v-model="searchForm.term_dates"
                                size="sm"
                            >
                              <option value="">All</option>
                              <option value="current">Current</option>
                              <option value="expired">Expired</option>
                            </b-form-select>
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">Membership Type</label>
                            <Select2
                                v-model="searchForm.membership_type"
                                name="membership_type"
                                id="membership_type"
                                :options="membershipListSelect2"
                                :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}"
                            />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">Membership Status</label>
                            <Select2
                                v-model="searchForm.membership_status"
                                name="status"
                                id="status"
                                :options="statusListSelect2"
                                :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}"
                            />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">Safesport Status</label>
                            <Select2
                                v-model="searchForm.safe_sport_status"
                                name="safe_sport_status"
                                id="safe_sport_status"
                                :options="safe_sport_statuses"
                                :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}"
                            />
                          </div>
                        </div>

                        <div class="col-md-6">
                          <div class="form-group">
                            <label class="form-label">Background Screening Status</label>
                            <Select2
                                v-model="searchForm.background_screening_status"
                                name="background_screening_status"
                                id="background_screening_status"
                                :options="background_screening_statuses"
                                :settings="{ multiple: true, placeholder: 'Click to select one or multiple'}"
                            />
                          </div>
                        </div>

                        <div class="col-md-12 modal-searchbtn text-center">
                          <div class="form-group">
                            <button type="submit" class="btn btn-primary btn-blue">{{ button.SearchButton }}</button>
                          </div>
                          <div class="text-sm text-danger">{{ errors.not_found }}</div>
                        </div>
                      </div>
                    </form>
                  </b-modal>
                    <!-- Edit Position -->
                  <b-modal id="edit-position-modal" size="lg" :title="editPositionModal.title" ref="modal" @ok="handlePositionOk">
                    <form ref="form" @submit.stop.prevent="handlePositionSubmit">
                      <div class="row">
                        <div class="col-md-6">
                          <label class="pt-4 form-label" for="feedback-name">Member ID</label>
                          <input type="text" disabled class="form-control" v-model="editPositionModal.confirmationcode">
                        </div>
                        <div class="col-md-6">
                          <label class="pt-4 form-label" for="feedback-name">Member Name</label>
                          <input type="text" disabled class="form-control" v-model="editPositionModal.member">
                        </div>
                        <div class="col-md-6">
                          <label class="pt-4 form-label" for="feedback-name">Group</label>
                          <input type="text" disabled class="form-control" v-model="editPositionModal.groupname">
                        </div>
                        <div class="col-md-6">
                          <label class="pt-4 form-label" for="feedback-name">Position</label><span class="text-danger"> * </span>
                          <b-form-select
                              id="inline-form-custom-select-pref"
                              class="mb-2 mr-sm-2 mb-sm-0 select_position"
                              v-model="editPositionModal.content.position_id"
                              :options="positions"
                              :value="positions.id"
                              size="sm">
                          </b-form-select>
                          <div class="text-sm text-danger">{{ position_err }}</div>
                        </div>
                        <div class="col-md-6">
                          <label class="pt-4 form-label" for="feedback-name">Sport Discipline</label><span class="text-danger"> * </span>
                          <b-form-select
                              id="inline-form-custom-select-prefer"
                              class="mb-2 mr-sm-2 mb-sm-0 select_position"
                              v-model="editPositionModal.content.sport_discipline"
                              size="sm">
                            <option value="">Select Sport Discipline</option>
                            <option v-for="(discipline, index) in selectdisciplines" :value="index" :key="'types'+index">{{ discipline }}</option>
                          </b-form-select>
                          <div class="text-sm text-danger">{{ sport_discipline_err }}</div>
                        </div>
                        <div class="col-md-6">
                          <template v-if="getSiteProps('group.board_span_completed.enabled') && getSiteProps('group.board_span_completed.committeeId').includes(parseInt(editPositionModal.committeeid))">
                            <label class="pt-4 form-label" for="feedback-name">BoardSpan Completed Date</label>
                            <div class="input-group birthdate">
                              <div class="birthdaypicker">
                                <date-pick
                                    id="board_span_completed"
                                    v-model="editPositionModal.content.board_span_completed"
                                    :format="'YYYY-MM-DD'"
                                    :displayFormat="'MM/DD/YYYY'"
                                    v-mask="'##/##/####'"
                                ></date-pick>
                              </div>
                            </div>
                          </template>
                        </div>
                        <div class="col-md-6">
                          <label class="pt-4 form-label" for="feedback-name">Term From Date</label>
                          <div class="input-group birthdate">
                            <div class="birthdaypicker">
                              <date-pick
                                  id="term_from_date"
                                  v-model="editPositionModal.content.effective_date"
                                  :format="'YYYY-MM-DD'"
                                  :displayFormat="'MM/DD/YYYY'"
                                  v-mask="'##/##/####'"
                              ></date-pick>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-6">
                          <label class="pt-4 form-label" for="feedback-name">Term To Date</label>
                          <div class="input-group birthdate">
                            <div class="birthdaypicker">
                              <date-pick
                                  id="term_to_date"
                                  v-model="editPositionModal.content.expiration_date"
                                  :format="'YYYY-MM-DD'"
                                  :displayFormat="'MM/DD/YYYY'"
                                  v-mask="'##/##/####'"
                              ></date-pick>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </b-modal>
                  <b-modal
                      id="modal-bulk-email"
                      ref="modal"
                      title="Send Email"
                      size="lg"
                      :ok-title="btnTitle"
                      :ok-disabled="btnDisabled"
                      @show="resetBulkEmailModal"
                      @hidden="resetBulkEmailModal"
                      @ok="handleSendEmailBtn"
                  >
                    <form ref="form" @submit.stop.prevent="bulkEmailAction">
                      <div class="row">
                        <div class="col-md-6">
                          <b-form-group
                              label="From Name"
                              label-for="from_name"
                          >
                            <b-form-input
                                id="from_name"
                                v-model="bulkEmail.from_name"
                                required
                            ></b-form-input>
                            <span class="text-sm text-danger">{{ validErrors.name}}</span>
                          </b-form-group>
                        </div>
                        <div class="col-md-6">
                          <b-form-group
                              label="From Email"
                              label-for="from_email"
                          >
                            <b-form-input
                                id="from_email"
                                v-model="bulkEmail.from_email"
                                required
                                type="email"
                            ></b-form-input>
                            <span class="text-sm text-danger">{{ validErrors.email}}</span>
                          </b-form-group>
                        </div>
                      </div>


                      <b-form-group
                          label="Subject"
                          label-for="subject"
                          :state="subState"
                      >
                        <b-form-input
                            id="subject"
                            v-model="bulkEmail.subject"
                            required
                        ></b-form-input>
                        <span class="text-sm text-danger">{{ validErrors.sub}}</span>
                      </b-form-group>

                      <b-form-group
                          label="Message"
                          label-for="message"
                      >
                        <ckeditor
                            id="message"
                            v-model="bulkEmail.message"
                            placeholder="Enter message"
                            rows="3"
                            max-rows="6"
                            :config="editorConfig"
                            required
                        ></ckeditor>
                        <span class="text-sm text-danger">{{ validErrors.msg}}</span>
                      </b-form-group>
                    </form>

                  </b-modal>
                  <b-button-group>
                    <b-dropdown class="commitee_select" right text="With selected members... ">
                      <b-dropdown-item @click="validateRosterSelection('email')">Send a message</b-dropdown-item>
                      <b-dropdown-item @click="validateRosterSelection('delete')">Delete</b-dropdown-item>
                    </b-dropdown>
                  </b-button-group>
                </div>
              </div>
            </section>
          </div>
          <!-- /.row (main row) -->
        </div>
        <!-- /.container-fluid -->
      </section>
    </div>
    <!-- /.content -->
    <committee-member-lookup v-bind:selectedCommittee="selectedCommittee" v-on:committee-member-lookup-data="refreshData"></committee-member-lookup>
  </div>
</template>

<script>

import axios from "axios";
import Errors from "../../Errors.js";
import moment from "moment";
import $ from "jquery";
import "datatables.net-responsive-dt/js/responsive.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
import CustomAlert from "../CustomAlert";
import CommitteeMemberLookup from "./CommitteeMemberLookup";

let cachedData = {};


export default {
  name: "CommitteeMember",
  components: { CommitteeMemberLookup,CustomAlert },

  data() {
    return {
      exportProgress: false,
      safesportRefreshing: false,
      loaded: false,
      perPage: 10,
      currentPage: 1,
      errors: new Errors(),
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: false,
        },
        {
          key: "committee",
          sortable: true,
        },
        {
          key: "group_text",
          label: "Group",
          sortable: true,
        },
        {
          key: "sport_discipline",
          sortable: true,
        },
        {
          key: "safe_sport",
          sortable: true,
        },
        {
          key: "screening",
          sortable: true,
        },
        {
          key: "payment",
          sortable: true,
        },
        {
          key: "membership_text",
          sortable: true,
        },
        {
          key: "actions",
          sortable: false,
        },
      ],
      items: [],
      viewEntryOptions: [
        { value: 5, text: "5" },
        { value: 10, text: "10" },
        { value: 20, text: "20" },
      ],
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
      editModal: {
        editing: true,
        id: "edit-modal",
        title: "",
        content: {},
      },
      positions: [],
      multiplePositions: [],
      position_err:'',
      sport_discipline_err:'',
      editPositionModal: {
        id: "edit-position-modal",
        title: "",
        member: "",
        memberid: '',
        confirmationcode:"",
        groupname:"",
        content: {
          position_id: 0,
          sport_discipline:'',
          effective_date:'',
          expiration_date:'',
          board_span_completed:''
        },
      },
      selected: [],
      allSelected: false,
      indeterminate: false,
      bulkEmail:{
        users:[],
        subject:"",
        from_name:"",
        from_email:"",
        message:""
      },
      btnDisabled:false,
      btnTitle:'Send Email',
      subState:null,
      nameState:null,
      emailState:null,
      msgState:null,
      groups: [],
      disciplines:[],
      selectdisciplines:[],
      safe_sport_statuses:[],
      background_screening_statuses:[],
      committeeList: [],
      validErrors:{
        name:'',
        email:'',
        sub:'',
        msg:''
      },
      button: {
        SearchButton: 'Search'
      },
      filtersApplied: false,
      searchForm: {
        confirmation_code:'',
        first_name: '',
        last_name: '',
        groups:[],
        discipline:[],
        position:[],
        term_dates: '',
        membership_type:[],
        membership_status: [],
        safe_sport_status: [],
        background_screening_status:[]
      },
      selectedCommittee: {
        id: '',
        name: ''
      },
      editorConfig: {
        baseFloatZIndex:10000,
        toolbar: [
          {
            name: 'document',
            items: [
              'Source', 'Heading',
              '-', 'Bold', 'Italic', 'Underline','NumberedList', 'BulletedList',
              '-',
              'Outdent', 'Indent',
              '-',
              'Undo', 'Redo',
            ]
          },
          { name: 'styles', items: [ 'Format', 'Font', 'FontSize' ] },
          { name: 'links', items: ['Link', 'Unlink'] },
        ],
      }
    };
  },

  filters: {
    productStatus(value) {
      return value ? "Active" : "Inactive";
    },
    formatDate(value) {
      if (value) {
        return moment(String(value)).format("MM/DD/YYYY");
      }
    },
  },
  methods: {
    formatlongDate(datevalue) {
      let date = moment(datevalue);
      if (date.isValid()) {
        return date.format('MM/DD/YYYY');
      } else {
        return 'N/A';
      }
    },
    loadPositions()
    {
      axios
            .get(process.env.VUE_APP_URL + "api/committee_positions")
            .then((response) => {
              let positions = Object.keys(response.data.committee_positions);
              if(positions.length && this.positions.length == 0){
                this.positions.push({value:0, text: 'Select Position'});
                positions.forEach((code,value) => {
                  this.positions.push({ value: response.data.committee_positions[code].id, text: response.data.committee_positions[code].position });
                });
                this.positions.sort((a, b) => a.text.localeCompare(b.text));
              }

              let multiplePositions = Object.keys(response.data.committee_positions);
              if(multiplePositions.length && this.multiplePositions.length == 0){
                this.multiplePositions.push({id:0, text: 'Select Position'});
                multiplePositions.forEach(code => {
                  this.multiplePositions.push({ id: response.data.committee_positions[code].id, text: response.data.committee_positions[code].position });
                });
                this.multiplePositions.sort((a, b) =>  a.text.localeCompare(b.text));
              }

            })
            .catch((error) => (this.errors = error.response.data));
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    addRow(button) {
      (this.editModal.editing = false),
        (this.editModal.title = "Add Committee Member");
      let id = localStorage.getItem("id");
      this.editModal.content = {
        committee: "",
        committee_group_id: "",
        membership: [],
        updated_by: id,
        created_by: id,
        discount_type: 1,
        status: 1,
      };
      this.$root.$emit("bv::show::modal", "edit-modal", button);
    },
    viewRow(item, index, button) {
      this.infoModal.title = "View " + item.committee;
      this.infoModal.content = item;
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    editPosition(id,position_id,effective_date,expiration_date,confirmationcode,membername,groupname,sport_discipline,board_span_completed,committeeid){
      this.editPositionModal.title = "Update Group Member";
      this.editPositionModal.memberid = id;
      this.editPositionModal.committeeid = committeeid;
      this.editPositionModal.content.position_id = position_id;
      this.editPositionModal.content.sport_discipline = (!isNaN(parseFloat(sport_discipline)) && isFinite(sport_discipline))?sport_discipline:'';
      this.editPositionModal.content.effective_date = effective_date;
      this.editPositionModal.content.expiration_date = expiration_date;
      this.editPositionModal.content.board_span_completed = board_span_completed;
      this.editPositionModal.member = membername;
      this.editPositionModal.groupname = groupname;
      this.editPositionModal.confirmationcode = confirmationcode;
      this.position_err = '';
      this.sport_discipline_err = '';
      this.$root.$emit("bv::show::modal", "edit-position-modal");
    },
    editRow(item, button) {
      (this.editModal.editing = true),
        (this.editModal.title = "Update " + item.committee);
      this.editModal.content = item;
      this.$root.$emit("bv::show::modal", "edit-modal", button);
    },
    
    deleteRow(id) {
      confirm("Are you sure to delete this item?");
      axios
        .delete(process.env.VUE_APP_URL + "api/committee/delete/" + id, {
          headers: this.adminHeaders,
        })
        .then((response) => {
          this.alertMessage = "Group Members deleted successfully";
          this.displayAlert = true;
          this.resetEditModal();
        })
        .catch((error) => {
          (error) => (this.errors = error.response.data);
        });
      return false;
    },

    //Modals
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    resetEditModal() {
      this.errors.clear();
      this.loaded = false;
      axios
        .get(process.env.VUE_APP_URL + "api/committee", {
          headers: this.adminHeaders,
        })
        .then((response) => {
          cachedData = response.data.committees;
          this.items = cachedData;
          this.loaded = true;
        })
        .catch((error) => (this.errors = error.response.data));
      this.loaded = true;
    },

    // Submitting
    onFail(errors) {
      this.errors.record(errors);
    },

    handleOk(bvModalEvt) {
      bvModalEvt.preventDefault();
      let url = process.env.VUE_APP_URL + "api/committee";
      let requestType = "post";

      if (this.editModal.editing) {
        url = process.env.VUE_APP_URL + "api/committee/update/" + this.editModal.content.id;
        requestType = "patch";
      }

      this.handleSubmit(requestType, url)
        .then(() => {
          this.$nextTick(() => {
            let message = this.editModal.editing ? "saved" : "created";
            alert("Group Member has been " + message + ".");
            this.$bvModal.hide("edit-modal");
          });
        })
        .catch(() => false);
    },
    handlePositionOk(bvModalEvt){
      bvModalEvt.preventDefault();
      let url = process.env.VUE_APP_URL + "api/committee_member_position/update/"+ this.editPositionModal.memberid;
      let requestType = "post";
      let valid = true;
      this.position_err = "";
      this.sport_discipline_err = "";
      if(this.editPositionModal.content.position_id == 0)
      {
        this.position_err = "Position Field is Required";
        valid = false;
      }
      if(this.editPositionModal.content.sport_discipline == '')
      {
        this.sport_discipline_err = "Sport Discipline is Required";
        valid = false;
      }
      if(valid)
      {
        this.handlePositionSubmit(requestType, url)
        .then(() => {
          this.$nextTick(() => {
            this.alertMessage = "Group Member has been Updated.";
            this.displayAlert = true;
            this.$bvModal.hide("edit-position-modal");
            this.table.ajax.reload();
          });
        })
        .catch(() => false);
      }
      
    },
    handlePositionSubmit(requestType, url) {
      this.editPositionModal.content.board_span_completed = this.getSiteProps('group.board_span_completed.enabled') && this.getSiteProps('group.board_span_completed.committeeId').includes(parseInt(this.editPositionModal.committeeid)) ? this.editPositionModal.content.board_span_completed : null;
      return new Promise((resolve, reject) => {
        axios[requestType](url, this.editPositionModal.content, {
          headers: this.adminHeaders,
        })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            this.onFail(error.response.data.errors);
            reject(error.response.data);
          });
      });
    },
    handleSubmit(requestType, url) {
      return new Promise((resolve, reject) => {
        axios[requestType](url, this.editModal.content, {
          headers: this.adminHeaders,
        })
          .then((response) => {
            resolve(response.data);
          })
          .catch((error) => {
            this.onFail(error.response.data.errors);
            reject(error.response.data);
          });
      });
    },
    isFutureDate(date) {
      const currentDate = new Date();
      return date > currentDate;
    },
    loadGroup() {
      axios
        .get(process.env.VUE_APP_URL + "api/committee_groups", {
          headers: this.adminHeaders,
        })
        .then((response) => {
          let groups = Object.keys(response.data.committee_groups);
          if (groups.length && this.groups.length == 0) {
            groups.forEach((code, value) => {
              this.groups.push({ value: response.data.committee_groups[code].id, text: response.data.committee_groups[code].name });
            });
          }
          console.log(this.groups);
        })
        .catch((error) => (this.errors = error.response.data));
    },
    loadCommittee() {
      axios
        .get(process.env.VUE_APP_URL + "api/committee", {
          headers: this.adminHeaders,
        })
        .then((response) => {
          let committees = Object.keys(response.data.committees);
          if (committees.length && this.committees.length == 0) {
            committees.forEach((code, value) => {
              this.committees.push({ value: response.data.committees[code].id, text: response.data.committees[code].committee });
            });
          }
          console.log(this.committees);
        })
        .catch((error) => (this.errors = error.response.data));
    },
    formatDate(value) {
      let date = moment(value, "YYYY-MM-DD hh:mm:ss");
      if (date.isValid()) {
        return date.format('MM/DD/YYYY');
      } else {
        return 'N/A';
      }
    },
    refreshSafeSport(){
      this.safesportRefreshing = true;
      this.table.ajax.reload();
    },
    advancedSearch() {
      this.table.draw();
      this.filtersApplied = true;
      this.$refs['modal-member-report'].hide();
    },
    getCommitteeLists()
    {
      axios.get(this.basePath+'api/committee-list')
          .then((res) => {
            let commiteelist = res.data.data;

            let commiteelists = Object.keys(commiteelist);
            commiteelists.forEach(code => {
              this.committeeList.push({ id: code, text: commiteelist[code] });
            });
            this.committeeList.sort((a, b) => a.text.localeCompare(b.text));
          })
          .catch((error) => {
            console.log('Error:'+ error);
          }).finally(() => {
      });
    },
    exportCsv(){
      let self = this;
      $.ajax({
        url: process.env.VUE_APP_URL + "api/reports/export/committee-member-export",
        type: 'post',
        "data": this.table.ajax.params(),
        beforeSend: function(){
          self.exportProgress = true;
        },
        "success": function(res, status, xhr) {
          self.exportProgress = false;
          if(res.file_url != undefined) window.open(res.file_url, '_blank');
        },
        error: function(err){
          console.log(err);
          self.exportProgress = false;
        }
      });
    },
    refreshList(event){
      this.searchForm = {
        confirmation_code:'',
        first_name: '',
        last_name: '',
        groups: [],
        discipline: [],
        position: [],
        term_dates: '',
        membership_type: [],
        membership_status: [],
        safe_sport_status: [],
        background_screening_status: [],
      };
      this.table.ajax.reload();
      this.filtersApplied = false;
    },

    validateRosterSelection(typ){
      this.showLoader = true;
      if(this.selected.length>0) {
        if(typ=="email") {
          this.validErrors = [];
          this.$bvModal.show('modal-bulk-email');
        }else if(typ=="delete"){
          this.bulkDelAction();
        }
      }else{
        this.showLoader = false;
        this.$bvModal.msgBoxOk('Select at least one Member', {
          title: 'Required',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          headerClass: 'p-2 border-bottom-0',
          footerClass: 'p-2 border-top-0',
          centered: true
        })
            .then(value => {})
            .catch(err => {})
      }
    },
    bulkDelAction(){
      this.displayAlert = false;
      this.$bvModal.msgBoxConfirm('Please confirm that you want to delete the selected members from their groups.', {
        title: 'Please Confirm',
        size: 'md',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
          .then(value => {
            if(String(value)=="true") {
              axios.post(this.basePath + 'api/committee_member_blk_delete', this.selected, {headers: this.clubHeaders})
                  .then(function (response) {
                    this.alertMessage = "Group Members deleted successfully";
                    this.displayAlert = true;
                    this.selected=[];
                    this.indeterminate=false;
                    this.allSelected = false;
                    this.table.ajax.reload();
                    this.showLoader = false;
                  }.bind(this))
                  .catch(function (error) {
                    console.log(error);
                  });
            }else{
              this.showLoader = false;
            }
          })
          .catch(err => {
            console.log(err);
          })
    },
    resetBulkEmailModal() {
      this.bulkEmail.subject = "";
      this.bulkEmail.from_name = "";
      this.bulkEmail.from_email = "";
      this.bulkEmail.message = "";
      this.subState = null;
      this.nameState = null;
      this.emailState = null;
      this.msgState = null;
      this.showLoader = false;
    },
    handleSendEmailBtn(bvModalEvt) {
      bvModalEvt.preventDefault();
      this.bulkEmailAction();
    },
    async bulkEmailAction() {
      if (!this.checkFormValidity()) {
        return;
      }

      this.btnDisabled = true;
      this.btnTitle = 'Sending...';
      this.bulkEmail.users = this.selected;
      await axios.post(this.basePath + 'api/committee_member_blk_email', this.bulkEmail, {headers: this.clubHeaders})
          .then(function (response) {
            this.alertMessage = "Email message sent successfully to the selected group members";
            this.displayAlert = true;
            this.table.ajax.reload();
            this.selected=[];
            this.allSelected = false;
            this.indeterminate=false;
            this.showLoader = false;
          }.bind(this))
          .catch(function (error) {
            console.log(error);
            this.btnDisabled = false;
            this.btnTitle = 'Send Email';
          });

      this.$nextTick(() => {
        this.btnDisabled = false;
        this.btnTitle = 'Send Email';
        this.$bvModal.hide('modal-bulk-email');
      });
    },
    checkFormValidity() {
      let valid = true;
      this.validErrors = [];
      if(this.bulkEmail.from_name == "")
      {
        this.validErrors.name = "From Name is Required";
        valid = false;
      }
      let re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if(!re.test(this.bulkEmail.from_email)){
        this.validErrors.email = "From Email is Required";
        valid = false;
      }
      if(this.bulkEmail.subject == "")
      {
        this.validErrors.sub = "Subject is Required";
        valid = false;
      }
      if(this.bulkEmail.message == ""){
        this.validErrors.msg = "Message is Required";
        valid = false;
      }
      return valid
    },
    getDiscipline(){
      axios.get(this.basePath+"api/getDisciplines/Committee")
          .then(response => {
            let disc = response.data.data;
            let discipline_codes = Object.keys(disc);
            discipline_codes.forEach(code => {
              let discipline_code =  disc[code].split(' - ');
              this.disciplines.push({ id: code, text: disc[code] == "NA - N/A" ? "N/A" : disc[code] });
            });
          })
          .catch(err => {
            // Manage the state of the application if the request
            // has failed
          })
    },
    getMembership(){
      axios.get(this.basePath + "api/getMemberships")
          .then((response) => {
            this.membershipData = response.data.data;
            delete this.membershipData[6]; //remove annual club or club active
            if(this.subdomain == 'shooting'){
              delete this.membershipData[4]; //remove supporting membership
              delete this.membershipData[9]; //remove 5 year club
            }
          })
          .catch(error=>{
            console.log("error getting Membership info")
          })
    },
    getSafeSportStatus(){
      this.safe_sport_statuses.push({ id: 0, text:'Not Started' });
      this.safe_sport_statuses.push({ id: 1, text:'In Progress'});
      this.safe_sport_statuses.push({ id: 3, text:'Complete' });
      this.safe_sport_statuses.push({ id: 13, text:'Expired' });
    },
    getBackgroundScreeningStatus(){
      this.background_screening_statuses.push({ id: 0, text: 'Not Started' });
      this.background_screening_statuses.push({ id: 1, text: 'In Progress' });
      this.background_screening_statuses.push({ id: 2, text: 'Complete' });
      this.background_screening_statuses.push({ id: 7, text: 'In Complete' });
      this.background_screening_statuses.push({ id: 6, text: 'Expired' });
    },
    getSelectDiscipline() {
      axios.get(this.basePath+"api/getDisciplines/Committee")
          .then(response => {
            this.selectdisciplines = response.data.data;
          })
          .catch(err => {
            // Manage the state of the application if the request
            // has failed
          })
    },
    selectCommittee(item) {
      const selectedGroupId = this.searchForm.groups;
      if (selectedGroupId) {
        this.selectedCommittee.id = selectedGroupId;
        this.selectedCommittee.name = this.committeeList.find(committee => committee.id === selectedGroupId).text;
      }
    },
    refreshData(){
      this.table.draw();
      this.$bvModal.hide('committee-member-lookup-modal');
    },
  },

  // created() {
  //   if (Object.keys(cachedData).length === 0) {
  //     axios
  //       .get(process.env.VUE_APP_URL + "api/committee_members", {
  //         headers: this.adminHeaders,
  //       })
  //       .then((response) => {
  //         cachedData = response.data.committee_members;
  //         this.items = cachedData;
  //         this.loaded = true;
  //       })
  //       .catch((error) => (this.errors = error.response.data));
  //     return false;
  //   }
  //   this.items = cachedData;
  //   this.loaded = true;
  // },
  computed: {
    rows() {
      return this.items.length;
    },
    statusListSelect2(){
      let list = [];
      this.membershipStatus.forEach((item, index) => {
        if(index > 0) list.push({ id: index, text: item });
      })
      return list;
    },
    membershipListSelect2(){
      let keys = Object.keys(this.membershipData);
      return keys.map(key => {
        return { id: key, text: this.membershipData[key]  }
      });
    }
  },

  mounted() {
    // this.loadGroup();
    // this.loadCommittee();
    this.getCommitteeLists();
    this.getDiscipline();
    this.getSelectDiscipline();
   this.loadPositions();
    this.getMembership();
    this.getSafeSportStatus();
    this.getBackgroundScreeningStatus();
    this.getMembershipStatus();
    let self = this;
    this.table = $("#cm_list_table")
      .on('preInit.dt', function () {
        $("#cm_list_table thead").addClass('thead-dark');
      }).DataTable({
        dom: 'lfrtip',
        bFilter: true,
        processing: true,
        serverSide: true,
        pageLength: 10,
        rowReorder: {
          selector: 'td:nth-child(2)'
        },
        responsive: true,
        ajax: {
          url: process.env.VUE_APP_URL + "api/committee_members_list",
          type: 'post',
          data: function (d) {
            d.safe_sport_refresh = self.safesportRefreshing;
            d.confirmation_code = $('#confirmation_code').val() || self.searchForm.confirmation_code;
            d.first_name = $('#first_name').val() || self.searchForm.first_name;
            d.last_name = $('#last_name').val() || self.searchForm.last_name;
            d.groups = $('#groups').val() || self.searchForm.groups;
            d.discipline = $('#discipline').val() || self.searchForm.discipline;
            d.position = $('#position').val() || self.searchForm.position;
            d.term_dates = $('#term_dates').val() || self.searchForm.term_dates;
            d.membership_type = $('#membership_type').val() || self.searchForm.membership_type;
            d.membership_status = $('#membership_status').val() || self.searchForm.membership_status;
            d.safe_sport_status = $('#safe_sport_status').val() || self.searchForm.safe_sport_status;
            d.background_screening_status = $('#background_screening_status').val() || self.searchForm.background_screening_status;
            d.search['value'] = d.search['value'].replace(/^0+/, '');
          }
        },
        initComplete: function( settings, json ) {
          this.safesportRefreshing = false;
        },
        columns: [
          { title: '', data: 'id', name: 'id', orderable: false, sortable: false, class:'committee_member_table', render: (data,type,row,meta)=>{
              return `<input type="checkbox" value="${row.id}" class="bulkChkBox" id="bulkChkBox${row.id}" />`;
          }},
          { title: 'Group', data: "committee_name", name: "committee.committee", render: (data) => { return data || "N/A"; } },
          { title: 'Member ID', data: 'confirmation_code', name: 'member.confirmation_code', render: (data) => { return data || "N/A"; } },
          { title: 'First Name', data: "first_name", name: "personal_details.first_name", render: (data) => { return data || "N/A"; } },
          { title: 'Last Name', data: "last_name", name: "personal_details.last_name", render: (data) => { return data || "N/A"; } },
          { title: 'Effective Date', data: "start_date", name: "member.start_date", render: (data) => { return this.formatDate(data) }, visible:false },
          { title: 'Position', data: "position", name: "position", render: (data) => { return data || "N/A"; } },
          { title: 'Region', data: "region_text", name: "region_text", render: (data) => { return data || "N/A"; }, visible: this.subdomain != "shooting", searchable: this.subdomain != "shooting" },
          { title: 'Sport Discipline', data: "sport_discipline_text", name: "sport_discipline_text", render: (data) => { return data || "N/A"; } },
          { title: 'Membership Type', data: "membership_type", name: "membership_type", render: (data) => { return data || "N/A"; } },
          { title: 'Membership Expiration', data: "valid_thru", name: "member.valid_thru", render: (data) => { return this.formatDate(data) } },
          { title: 'Membership Status', data: "membership_status", name: "membership_status", render: (data) => { return data || "N/A"; } },
          { title: 'Safe Sport Training Status/Expiration', data: "safe_sport_status_text", name: "safe_sport_status_text", render: (data) => { return data || "N/A"; } },
          { title: 'Background Screening Status/Expiration', data: "background_screening_status_text", name: "background_screening_status_text", render: (data) => { return data || "N/A"; } },
          { title: 'BoardSpan Completed', data: "board_span_completed", name: "board_span_completed", visible: this.getSiteProps('group.board_span_completed.enabled'), searchable: false,
            render: (data) => { return (data != null ? this.formatDate(data) : "N/A"); }
          },
          { title: 'Actions', data: "actions", name: "actions", orderable: false, searchable: false,
            render: (data, type, row, meta) => {
              return `<a class="actions-click" href="/admin/registrations/${row.member_id}?isCommittee=true"><i class="fas fa-eye"></i></a><a class="editPosition" confirmationcode="${row.member.confirmation_code !== undefined ? row.member.confirmation_code : ""}" membername="${row.personal_details.full_name !== undefined ? row.personal_details.full_name : ""}" groupname="${row.committee != null && row.committee.committee !== undefined ? row.committee.committee : ""}" committeeid="${row.committee_id}" memberid="${row.id}" position_id="${row.position_id}" sport_discipline="${row.sport_discipline}" effective_date="${row.effective_date}" expiration_date="${row.expiration_date}" board_span_completed="${row.board_span_completed}" href="javascript:void(0);"><i class="ml-1 fas fa-pencil-alt"></i></a>
              <a class="action-deletes" data-id="${row.id}" href="javascript:void(0);"><i class="fas fa-trash"></i></a>`
            }
          },
        ],
        columnDefs: [
          //{ targets: 6, orderable: false, searchable: false },
          { targets: 6, visible: this.subdomain == "waterski" },
        ],
        order: [[4,'asc'],[3,'asc']]
      });
      $(document).on("click", ".action-deletes", async function(){
        let id = $(this).data("id");
        self.displayAlert = false;
        self.$bvModal.msgBoxConfirm('Are you sure to delete member from group?', {
          title: 'Please Confirm',
          size: 'md',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'YES',
          cancelTitle: 'NO',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true
        })
            .then(async value => {
              if(String(value)=="true")
              {
                let response = await axios.delete(process.env.VUE_APP_URL + "api/committee_members/delete/" + id);
                self.alertMessage = "Group Member deleted successfully";
                self.displayAlert = true;
                self.table.ajax.reload();
              }
            })
            .catch(err => {
              console.log(err);
            })
      });

    $(document).on("change", ".bulkChkBox", function(){
      if(self.selected.some(data => data === $(this).val())){
        self.selected = self.selected.filter((t) => t !== $(this).val());
      }
      else{
        self.selected.push($(this).val());
      }
    });

    document.addEventListener("click", function(e){
      if(e.target && e.target.parentNode.className == "editPosition"){
        let memberid = e.target.parentNode.getAttribute('memberid');
        let position_id = e.target.parentNode.getAttribute('position_id');
        let sport_discipline = e.target.parentNode.getAttribute('sport_discipline');
        let effective_date = e.target.parentNode.getAttribute('effective_date');
        let expiration_date = e.target.parentNode.getAttribute('expiration_date');
        let board_span_completed = e.target.parentNode.getAttribute('board_span_completed');
        let confirmationcode = e.target.parentNode.getAttribute('confirmationcode');
        let membername = e.target.parentNode.getAttribute('membername');
        let groupname = e.target.parentNode.getAttribute('groupname');
        let committeeid = e.target.parentNode.getAttribute('committeeid');
        self.editPosition(memberid,position_id,effective_date,expiration_date,confirmationcode,membername,groupname,sport_discipline,board_span_completed,committeeid);
      }
    });
  }
};
</script>
<style>
body.modal-open.cke_dialog_open  #modal-bulk-email___BV_modal_outer_ #modal-bulk-email{
  display: none !important;
}
.committee_member_table{
  width: 23px !important;
}
 .commitee_select  .btn.btn-secondary {
  border: 1px solid #143c6a !important;
  color: #143c6a !important;
  background: transparent !important;
  font-size: 14px !important;
}
#cm_list_table{
  width: 100% !important;
  }
.term_date {
  height: calc(1.8125rem + 9px);
  font-size: 95%;
  border: 1px solid #ced4da !important;
}
.select_position{
  height: calc(2.25rem + 3px)!important;
  font-size: 100%;
}

</style>